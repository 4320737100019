<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ now }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <dashboard-component />
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
import moment from "moment";

const DashboardComponent = () => import("../components/DashboardComponent.vue");

export default {
  components: {
    DashboardComponent
  },
  data: () => ({
    now: moment().format("DD-MMM, ddd")
  })
};
</script>
